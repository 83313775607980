import { useElementVisibility, watchOnce } from '@vueuse/core';

/**
 * Wait for parent to be visible and then scroll active element into view. Waiting for the parent
 * to be visible avoids programmatically scrolling the active element into view vertically.
 */
export const scrollActiveElementIntoView = (params = {}) => {
  const {
    parent,
    activeElement,
    options = { behavior: 'smooth', block: 'nearest', inline: 'center' },
    immediate = false,
  } = params;

  if (!parent || !activeElement) {
    return;
  }

  const scrollIntoView = () => {
    // DEBUG: Log active element to troubleshoot race conditions
    const tag = `${activeElement.outerHTML.split('>')[0]}>`;
    console.log(`scrollActiveElementIntoView(): ${tag}`);
    activeElement.scrollIntoView(options);
  };

  if (immediate) {
    scrollIntoView();
    return;
  }

  const isParentVisible = useElementVisibility(parent);

  if (isParentVisible.value) {
    scrollIntoView();
  }
  else {
    watchOnce(isParentVisible, (value) => {
      if (value) {
        scrollIntoView();
      }
    });
  }
};

/**
 * Sometimes refs aren't immediately available. This function can be used to wait for them.
 * @see https://nuxtjs.org/docs/features/nuxt-components/#the-client-only-component
 */
export const waitForRef = async (component, ref) => new Promise((resolve, reject) => {
  const checkForRef = async (count = 10) => {
    await component.$nextTick();

    if (component?.$refs[ref]) {
      resolve();
    }
    else if (count > 0) {
      checkForRef(count - 1);
    }
    else {
      const mesg = `${ref || 'unknown'} ref not found in ${component?.$options?.name || 'unknown'} component`;
      reject(new Error(mesg));
    }
  };

  checkForRef();
});
